import React, { useEffect, useState } from 'react';
import { Box, Avatar, Typography, useTheme } from '@mui/material';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

function AuthorInfo({ userId }) {
  const theme = useTheme();
  const [author, setAuthor] = useState({
    fullName: '',
    profilePicture: '/default-avatar.jpg', // Default profile picture
    currentCompany: null,
    currentPosition: null,
    bio: null,
  });

  useEffect(() => {
    const fetchAuthorInfo = async () => {
      if (userId) {
        try {
          const userRef = doc(db, 'users', userId);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            const userData = userSnap.data();

            // Combine firstName and lastName for fullName
            const fullName = `${userData.firstName || ''} ${userData.lastName || ''}`.trim() || 'Unnamed User';

            setAuthor({
              fullName,
              profilePicture: userData.ProfilePic || '/default-avatar.jpg',
              currentCompany: userData.currentCompany || null,
              currentPosition: userData.currentPosition || null,
              bio: userData.bio || null,
            });
          } else {
            console.error('No such document!');
          }
        } catch (error) {
          console.error('Error fetching author info:', error);
        }
      }
    };

    fetchAuthorInfo();
  }, [userId]);

  return (
    <Box
      display="flex"
      alignItems="center"
      mb={4}
      sx={{
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        padding: '16px',
        borderRadius: 2,
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
      }}
    >
      {/* Profile Picture */}
      <Avatar
        alt={author.fullName}
        src={author.profilePicture}
        sx={{ width: 64, height: 64, mr: 2 }}
      />

      <Box>
        {/* Author Name */}
        <Typography variant="h6" sx={{ fontWeight: 600, color: theme.palette.text.primary }}>
          {author.fullName}
        </Typography>

        {/* Position and Company */}
        {(author.currentPosition || author.currentCompany) && (
          <Typography
            variant="body2"
            sx={{ color: theme.palette.text.secondary, mt: 0.5 }}
          >
            {author.currentPosition && author.currentCompany
              ? `${author.currentPosition} at ${author.currentCompany}`
              : author.currentPosition || `Works at ${author.currentCompany}`}
          </Typography>
        )}

        {/* Bio */}
        {author.bio && (
          <Typography variant="body2" sx={{ color: theme.palette.text.secondary, mt: 1 }}>
            {author.bio}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default AuthorInfo;
